<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8">
        <h1 class="mb-1">Bitácora de accesos</h1>
      </v-col>
      <v-col cols="12" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="modal = false"
                >Cancelar</v-btn
              >
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  updateDateFilter();
                "
              >
                Filtrar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :items="access"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          sort-by="date"
          class="mt-5"
          sort-desc
          item-key=".key"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              >
              </v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.date`]="{ item }">
            <span class>{{ item.date | filterDate }}</span>
          </template>

          <template v-slot:[`item.region`]="{ item }">
            <span>{{ item.ip.region }}</span>
          </template>

          <template v-slot:[`item.ip.organization`]="{ item }">
            <div class="isp">
              {{ item.ip.organization }}
            </div>
          </template>

          <template v-slot:[`item.domain`]="{ item }">
            <div class="isp">
              {{ item.domain }}
            </div>
          </template>


          <template v-slot:[`item.error`]="{ item }">
            <span v-if="item.error" class>{{ item.error.message }}</span>
          </template>

          <template v-slot:[`item.result`]="{ item }">
            <span v-if="item.result == 'success'" style="color: green">
              Exitoso</span
            >
            <span v-else style="color: red"> Fallido</span>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip v-if="item.email" right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.email)"
                >
                  {{ item.email }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.ip.ip`]="{ item }">
            <v-tooltip v-if="item.ip && item.ip.ip" right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.ip.ip)"
                >
                  {{ item.ip.ip }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="showDialog(item)" small color="primary">
              Ver mensajes
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td v-if="item.error" :colspan="headers.length" class="pa-4">
              <div class="error-login" v-if="item.error">
                <h3><b>Código del Error</b></h3>
                <p class="ml-3">{{ item.error.code }}</p>
                <h3><b>Mensaje del Error</b></h3>
                <p class="ml-3">{{ item.error.message }}</p>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "messages",
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      balance: 0,
      randomKey: 0,
      modal: false,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      snackbar: false,
      snackbarText: "",
      addModal: false,
      viewDialog: false,
      selectedItem: null,
      access: [],
      expanded: [],
      headers: [
        {
          text: "Dominio",
          value: "domain",
          class: "nowrap",
          width : '300px'
        },
        {
          text: "Resultado",
          value: "result",
          sortable: false,
        },

        {
          text: "Correo electrónico",
          value: "email",
          class: "nowrap",
        },
        {
          text: "IP",
          value: "ip.ip",
          class: "nowrap",
        },
        {
          text: "Región",
          value: "region",
          class: "nowrap",
          width: "300px",
        },
        {
          text: "Proveedor de Internet",
          value: "ip.organization",
          width: "320px",
        },

        {
          text: "Fecha",
          value: "date",
          class: "nowrap",
          width: "200px",
        },
      ],
    };
  },
  filters: {
    filterDate: function (timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm:ss");
    },
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  async mounted() {
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.$store.commit("setSearchTerm", "acceso");
    // this.getCurrentBalance();

    this.getLogs();
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    async getLogs() {
      if (this.date[0] && this.date[1]) {
        this.loading = true;
        await this.$binding(
          "access",
          db
            .collection("accessLog")
            .where(
              "date",
              ">",
              moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
            )
            .where(
              "date",
              "<",
              moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
            )
        );

        this.loading = false;
      }
    },

    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }

      this.getLogs();
    },

    showDialog(item) {
      this.selectedItem = item;
      this.viewDialog = true;
    },
    getCurrentBalance() {
      axios
        .get("https://rest.messagebird.com/balance", {
          headers: {
            Authorization: "AccessKey " + "chzLLbR4TevKCpnrEtpzThulD",
          },
        })
        .then((response) => {
          this.balance = response.data.amount;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    newMessage() {
      this.randomKey = Math.random();
      this.addModal = true;
    },
    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.addModal = false;
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
  },
};
</script>

<style scoped>
.error-login {
  background-color: #e5e2e2b5;
  padding: 15px;
  border-radius: 30px;
}

.isp {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
